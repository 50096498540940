@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #607d8b;
}

$config: mat.m2-define-typography-config(
  $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
);
$primary: mat.m2-define-palette(mat.$m2-deep-purple-palette);
$accent: mat.m2-define-palette(mat.$m2-amber-palette);
$warn: mat.m2-define-palette(mat.$m2-red-palette);
$theme: mat.m2-define-light-theme(
  (
    typography: $config,
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    )
  )
);

.full-width {
  width: 100%;
}

mat-paginator {
  margin-top: 1.0rem;
}

a[mat-mdc-button], button[mat-mdc-button], button[mat-raised-button], button[mat-stroked-button], button[mat-ripple], .mat-mdc-tab.mdc-tab, label mat-label {
  letter-spacing: normal;
}

form.wave div.wave-spinner {
  height: 24px;
}

div.highlight em {
  font-weight: bold;
  font-style: normal;
}

safe-spinner.wave-12 {
  transform: scale(0.4, 0.3);
}

safe-spinner.wave-12 div.wave-spinner {
  height: 12px;
  margin: 0;
}

.mat-column {
  display: flex;
  flex-direction: column;
}

.mat-column.gap-1em {
  gap: 1em;
}

.mat-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mat-line.start {
  justify-content: start;
}

.mat-line.center {
  justify-content: center;
}

.mat-line.top {
  align-items: start;
}

.mat-line.bottom {
  align-items: end;
}

.mat-line.gap-1em {
  gap: 1em;
}

.mat-line.gap-0-5em {
  gap: 0.5em;
}

.mat-line.space-between {
  justify-content: space-between;
}

.mat-line.start {
  justify-content: start;
}

.mat-line.end {
  justify-content: end;
}

mat-card mat-card-header.safe-business-logo div.mat-mdc-card-header-text {
  width: 100%;
}

@include mat.all-component-themes($theme);
